import {
    computed
} from 'vue'

import {
    usequote
} from '@/pinia/api/useQuote.js'
import {
    usePriceStore
} from '@/pinia/api/useDetailOffers.js'
const store = usequote() // 主页的
const PriceStore = usePriceStore() // 店铺
export function usePrice(origin, type) { // type ==0 代表的是不锈钢网页的最新报价金钱 否则就是 店铺  
    const keys = Object.keys(origin.value)
    return keys.map(key => {
        return computed({
            get() {
                return '￥' + origin.value[key]
            },
            set(newVal) {
                newVal = newVal.replace(/￥/g, '')
                const result = Number(newVal)
                if (result === 0) {
                    origin.value[key] = ''
                    if (type == 0) {
                        if (key == 'fromPrice') {
                            store.params.tiny = ''
                        } else {
                            store.params.wide = ''
                        }
                    } else {
                        if (key == 'fromPrice') {
                            PriceStore.params.wide = ''
                        } else {
                            PriceStore.params.tiny = ''
                        }
                    }
                } else {
                    origin.value[key] = result
                    if (type == 0) {
                        if (key == 'fromPrice') {
                            store.params.tiny = result
                        } else {
                            store.params.wide = result
                        }
                    } else {
                        if (key == 'fromPrice') {
                            PriceStore.params.wide = result
                        } else {
                            PriceStore.params.tiny = result
                        }
                    }
                }
            }
        })
    })


}